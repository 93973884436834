// page.scss

/*---------------------------------------------------------------------
                      ↓ 메인 홈
-----------------------------------------------------------------------*/
#home {
  .content {
    margin-top: 30px;
  }
  .banner {
    height: 100%;
    border-radius: 4px;
    background-color: #e3e9f0;
    overflow: hidden;
    .annotation-box {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .img-box {
      width: 100%;
      height: 100%;
      background-size: auto;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../images/banner/banner.png');
    }
  }
  .user-box {
    padding: 30px 30px 20px 30px;
    border-radius: 4px;
    border: 1px solid #bcbfcb;
    .top-area {
      h5 {
        font-size: 20px;
        margin-bottom: 12px;
      }
      .btn-area {
        display: flex;
        margin: 0;
        .btn {
          flex: 1;
          height: 52px;
          font-size: 18px;
          font-weight: bold;
          margin-left: 16px;
          &:first-of-type {
            margin: 0;
          }
        }
      }
    }
    .dl-box {
      dl {
        height: auto;
        line-height: normal;
      }
      &.info {
        padding: 20px 0;
        margin: 26px 0 14px 0;
        border-top: 1px solid var(--bs-border);
        border-bottom: 1px solid var(--bs-border);
        dl {
          margin-bottom: 14px;
          &:last-of-type {
            margin-bottom: 0;
          }
          dt {
            color: #a4a5b6;
          }
        }
      }
      &.total {
        strong {
          display: inline-block;
          transform: translateY(3px);
          margin-right: 8px;
        }
      }
    }
  }
  ul.post-list {
    border: 1px solid var(--bs-border);
    li {
      border-bottom: 1px solid var(--bs-border);
      &:last-of-type {
        border-bottom: 0;
      }
      a {
        display: flex;
        justify-content: space-between;
        font-size: var(--fs-16);
        padding: 0 1rem;
        height: 46px;
        line-height: 46px;
        &:hover {
          background-color: #fafcff;
          cursor: pointer;
        }
        span {
          &.ellipsis {
            width: 450px;
          }
          &.date {
            color: #8c8ca1;
          }
        }
      }
    }
  }
}

/*---------------------------------------------------------------------
                      ↓ 공지사항, 교육자료실 상세
-----------------------------------------------------------------------*/
.post-box-wrap {
  .title {
    min-height: 44px;
    height: auto;
    margin-bottom: 18px;
  }
  .post-box {
    border-top: 2px solid var(--bs-dark) !important;
    border: 1px solid var(--bs-border);
    & > div {
      padding: 20px 24px;
    }
    .text-area {
      height: auto;
      min-height: 370px;
      font-weight: 500;
    }
    .dl-box {
      &.type-bar {
        border-bottom: 1px solid var(--bs-border);
        width: 100%;
        flex-direction: row;

        dl {
          // font-size: 18px;
          height: auto;
          line-height: normal;
          padding-left: 1rem;
          margin-left: 1rem;
          &:first-of-type {
            padding: 0;
            margin: 0;
            &:before {
              display: none;
            }
          }
          dt {
            font-weight: 500;
            margin-right: 8px;
            color: #a4a5b6;
          }
          dd {
            font-weight: 500;
            color: #6f6f67;
            span {
              color: #191919;
              font-weight: bold;
              margin-left: 6px;
            }
          }
        }
      }
    }
  }
}

/*---------------------------------------------------------------------
                      ↓ header
-----------------------------------------------------------------------*/
header {
  width: 100%;
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--bs-white) !important;
  section.top-area {
    border-bottom: 1px solid #d3d7de;
    .container {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 90px;
      h1 {
        font-size: 2rem;
      }
      .user-menu {
        position: relative;
        &:hover {
          ul {
            display: block;
          }
        }
        p {
          cursor: pointer;
          font-size: 16px;
          font-weight: 400px !important;
          color: #53535d;
          padding: 30px 30px 30px 17px;
          background: url(../images/ic_arrow_down.png) no-repeat 90% 50% / 12px;
          span {
            font-weight: bold;
            color: var(--bs-primary);
          }
        }
        ul {
          display: none;
          position: absolute;
          z-index: 1002;
          top: 70px;
          right: 0;
          width: 148px;
          padding: 24px;
          background-color: white;
          border-radius: 4px;
          box-shadow: 4px 2px 12px 4px rgb(0 0 0 / 10%);
          li {
            padding-bottom: 16px;
            a {
              font-weight: 500;
              color: #191919;
              transition: 0.2s;
              &:hover {
                color: var(--bs-primary);
              }
            }
            &:nth-of-type(3) {
              margin-bottom: 16px;
              border-bottom: 1px solid #c9ccdb;
            }
            &:last-of-type {
              padding-bottom: 0;
              a {
                color: var(--bs-primary);
              }
            }
          }
        }
      }
      .step-box {
        color: var(--bs-primary);
        padding: 1rem;
        font-size: revert;
        font-weight: 600;
      }
      .logo-add {
        margin-top: 3px;
        width: 29px;
      }
      .move-home {
        color: var(--bs-primary);
        margin: 0 0 0 1rem;
        font-size: revert;
        font-weight: 600;
      }
    }
  }
  section.nav-bar {
    background-color: #143255;
    // type-left (학생화면 외)
    &.type-left {
      .container {
        .nav {
          .nav-item {
            flex: none;
            width: 160px;
          }
        }
      }
    }
    .container {
      height: 48px;
      padding: 0;
      .nav {
        width: 100%;
        display: flex;
        .nav-item {
          flex: 12.5% 0 0;
          // 메뉴
          &:hover {
            a {
              &::before {
                background-color: var(--bs-primary);
              }
            }
          }
          a {
            width: 100%;
            position: relative;
            color: white;
            opacity: 0.6;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 48px;
            font-weight: 400;
          }
          .selected {
            background: #0a203a;
            color: white;
            opacity: 1;
            font-weight: 500;
          }
          // 서브메뉴
          &:hover {
            ul.sub-menu {
              display: block;
            }
          }
          .sub-menu {
            border-top: 2px solid var(--bs-white);
            display: none;
            background-color: #143255;
            box-shadow: 0 4px 4px 0 rgb(0 0 0 / 10%);
            padding: 8px;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            li {
              a {
                width: 100%;
                height: 38px;
                justify-content: center;
                font-size: 15px;
                font-weight: 400;
                opacity: 0.6;
                border-radius: 4px;
                &:hover {
                  opacity: 1;
                  background-color: #0a203a;
                }
              }
              .sub-selected {
                opacity: 1;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
}

/*---------------------------------------------------------------------
                      ↓ footer
-----------------------------------------------------------------------*/
footer {
  // margin-top: 5rem;
  background: #143255;
  overflow: hidden;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  height: 210px;
  // 공통
  .container {
    padding: 0;
  }
  .dl-box {
    &.type-bar {
      dl {
        font-weight: 400 !important;
        color: white !important;
        height: auto;
        line-height: 1;
        padding-left: 0.85rem;
        margin-left: 0.85rem;
      }
    }
  }
  // 상단
  .top-area {
    border-bottom: 1px solid #bcbfcb;
    padding: 20px 0;
    .dl-box {
      &.type-bar {
        dl {
          font-size: 14px;
          a {
            dd {
              font-weight: 400 !important;
              color: white !important;
              transition: 0.2s;
              opacity: 0.85;
            }
          }
          &:hover {
            a {
              dd {
                opacity: 1;
              }
            }
          }
          &:before {
            opacity: 0.85;
            height: 12px;
          }
        }
      }
    }
  }
  // 하단
  .bottom-area {
    padding: 40px 0;
    h5 {
      color: white !important;
      font-size: 14px !important;
      font-weight: 400;
      margin-bottom: 20px;
    }
    p {
      color: white !important;
      opacity: 0.6;
      font-size: 12px !important;
      font-weight: 400;
      margin-top: 6px;
      line-height: normal;
    }
    .dl-box {
      &.type-bar {
        dl {
          font-size: 12px;
          opacity: 0.6;
          &:before {
            opacity: 0.6;
            height: 10px;
          }
        }
        dt {
          font-weight: 400 !important;
        }
      }
    }
  }
}

/*---------------------------------------------------------------------
                      ↓ errors
-----------------------------------------------------------------------*/
#errors {
  width: 100%;
  height: calc(100vh - 100px);
  padding-bottom: 6rem;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    h2 {
      font-size: 6rem;
    }
    p {
      font-size: 1.5rem;
    }
    .btn {
      margin-top: 4rem;
    }
  }
}

/*---------------------------------------------------------------------
                      ↓ student
-----------------------------------------------------------------------*/
// 학생 - 자기역량관리 - NCS관리 - 신규추가
#student-ncs-register {
  .btn-group {
    flex-wrap: wrap;
    .btn {
      border-radius: 0;
      justify-content: flex-start;
      font-size: var(--fs-16);
    }
    input:checked + .btn {
      color: var(--bs-primary);
      font-weight: 600;
    }
    &.mainCate-search {
      border-top: 1px solid var(--bs-border);
      .btn {
        border-color: var(--bs-border);
        border-top: 0;
        flex: 0 0 25%;
        font-weight: 500;
        &:hover {
          background: var(--bs-primary-light);
        }
      }
    }
    &.subCate-search {
      max-height: 264px;
      width: 100%;
      overflow: auto;
      .btn {
        flex: 0 1 100%;
        border: 0;
        font-weight: 400;
      }
    }
  }
  .sec-subcategory {
    h5 {
      font-size: var(--fs-16);
      font-weight: 400;
    }
    .row {
      --bs-gutter-x: 0;
      border-left: 1px solid var(--bs-border);
      margin-top: 0.5rem;
      .col {
        flex: 0 0 33.33%;
        border: 1px solid var(--bs-border);
        border-left: 0;
        h6 {
          background: var(--bs-primary-light);
          border-bottom: 1px solid var(--bs-border);
          width: 100%;
          font-size: var(--fs-14);
          text-align: center;
          font-weight: 600;
          padding: 0.5rem;
        }
      }
    }
  }
}
// 학생 - 포트폴리오 - 상세
#program-resume-report {
  .split-table {
    word-break: break-word;
  }
}

// 교사 - 포트폴리오 - 상세
#program-resume-comment {
  .split-table {
    word-break: break-word;
  }
}

// 학생 - 전공직무2 - 상세보기
#program-majorJobEdu-report {
  .comment-list {
    word-break: break-word;
  }
}

// 학생 - 산업체 - 상세보기
#program-practiceAbility-report {
  .comment-list {
    word-break: break-word;
  }
}

// 학생 - 현장체험 - 상세보기
#program-visitIndustry-report {
  .comment-list {
    word-break: break-word;
  }
}

// 학생 - 취업마인드 - 상세보기
#student-employAbility-report {
  .comment-list {
    word-break: break-word;
  }
}

// 학생 - 전공직무능력1 - 신규작성(취득 자격증 추가)
#student-majorJob-register {
  .form-list {
    .dropdown {
      .dropdown-toggle {
        font-size: 1rem;
      }
      .dropdown-menu {
        padding: 0;
        .row {
          --bs-gutter-x: 0;
          .col {
            flex: 0 0 50%;
            border-right: 1px solid var(--bs-border);
            &:nth-child(2) {
              border-right: 0;
            }
            .btn-group {
              width: 100%;
              flex-wrap: wrap;
              max-height: 264px;
              overflow: auto;
              .btn {
                flex: 0 1 100%;
                border: 0;
                font-weight: 400;
                border-radius: 0;
                justify-content: flex-start;
                font-size: var(--fs-16);
              }
              input:focus + .btn {
                color: var(--bs-primary);
              }
              input:checked + .btn {
                color: var(--bs-primary);
                background: var(--bs-primary-light)
                  url('../images/ic_arrow_right_on.png') no-repeat right 0.75rem
                  center / 8px auto;
                font-weight: 600;
              }
              &.certNms {
                input:checked + .btn {
                  background: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
// 학생 - 매칭정보 - 종합보고서
#student-matchingInfo-report {
  .card {
    border: 1px solid var(--bs-border);
    padding: 2rem 3rem;
    background: var(--bs-white);
    section {
      padding: 3rem 0;
      border-top: 1px solid var(--bs-border);
      &:first-of-type {
        border: 0;
      }
    }
    .dl-box {
      dl {
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 0.5rem;
        height: auto;
        dt {
          width: 200px;
          flex-shrink: 0;
          font-size: var(--fs-16);
          color: var(--bs-gray-dark);
          text-align: left;
          font-weight: 500;
        }
        dd {
          font-size: var(--fs-16);
          font-weight: 500;
          flex: 1;
          text-align: left;
          .form-control {
            text-align: right;
          }
        }
        &:first-of-type {
          margin-top: 0;
        }
        &.bt {
          word-break: break-word;
          margin-top: 1rem;
          padding-top: 1rem;
          border-top: 1px solid var(--bs-border);
        }
      }
      &.bt-bold {
        margin-top: 1rem;
        border: 1px solid var(--bs-border);
        border-top: 2px solid var(--bs-dark);
        padding: 1.5rem;
      }
    }
    strong {
      display: block;
      font-size: var(--fs-16);
    }
    ul {
      li {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 1.5rem;
        &:first-of-type {
          margin-top: 0;
        }
        strong {
          margin-bottom: 0.25rem;
        }
        small {
          font-size: var(--fs-14);
        }
      }
      &.li-has-line {
        li {
          display: block;
          margin: 0;
          border-top: 1px solid var(--bs-border);
          padding: 1rem 0;
          &:first-of-type {
            border: 0;
            padding-top: 0;
          }
          p {
            word-break: break-word;
          }
        }
      }
    }
    .row {
      h6 {
        font-size: 40px;
        font-weight: 700;
        span {
          font-size: 30px;
          font-weight: 500;
          padding-left: 1rem;
        }
      }
      .ratio {
        width: 142px;
        .img-box {
          background-color: var(--bs-gray-200);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
}
/*---------------------------------------------------------------------
                      ↓ admEdu
-----------------------------------------------------------------------*/
.content {
  &.py {
    padding: 5rem 0;
    .title {
      margin-top: 0;
    }
  }
}
#admEdu-dashboard {
  .content {
    .summary {
      .card {
        background-repeat: no-repeat;
        background-position: right bottom;
        box-shadow: none;
        border-radius: 4px;
        padding: 1rem 1rem 3rem;
        strong {
          font-weight: 700;
          font-size: var(--fs-32);
          color: var(--bs-white);
        }
        p {
          font-weight: 700;
          font-size: var(--fs-16);
          color: var(--bs-white);
        }
        &.total-student {
          background-color: #5484ff;
          background-image: url('../images/ic_total_student.png');
        }
        &.new-student {
          background-color: #ffa654;
          background-image: url('../images/ic_new_student.png');
        }
        &.voucher-allotment {
          background-color: #66cb9f;
          background-image: url('../images/ic_voucher_allotment.png');
        }
        &.voucher-payment {
          background-color: #ef8892;
          background-image: url('../images/ic_voucher_payment.png');
        }
      }
    }
  }
}
#admEdu-voucher-lookup {
  .search-wrap {
    .search-box {
      .datepicker-box {
        display: flex;
        align-items: center;
        padding-right: 2rem;
        background: url('../images/ic_calendar.png') no-repeat right 0.5rem
          center;
        &.disabled {
          color: var(--bs-secondary);
          font-weight: 500;
          input {
            color: var(--bs-secondary);
            pointer-events: none;
          }
        }
        .react-datepicker-wrapper {
          display: inline-flex;
          flex-grow: 0;
          width: 100px;
          padding: 0 0.5rem;
          .react-datepicker__input-container {
            input {
              width: 100%;
              padding: 0;
              border: 0;
              font-size: 1rem;
              font-weight: 500;
              background: none;
              &:focus,
              &:active {
                outline: 0;
                border: 0;
              }
            }
          }
        }
        .react-datepicker__year-read-view--down-arrow,
        .react-datepicker__month-read-view--down-arrow,
        .react-datepicker__month-year-read-view--down-arrow,
        .react-datepicker__navigation-icon::before {
          top: 14px;
        }
      }
    }
  }
}

#modal-download {
  .modal-body {
    border: 0;
    padding: 0;
    p {
      font-weight: 600;
    }
    .border-box {
      padding: 0.75rem 1rem;
      margin-top: 0.5rem;
      .form-check {
        flex-shrink: 0;
        label {
          padding: 0.25rem 0;
          font-size: 1rem;
          font-weight: 500;
        }
      }
      .form-check {
        width: 100%;
      }
    }
  }
}
#modal-modify-alloment {
  .modal-body {
    padding-top: 0;
    .dl-box {
      dl {
        justify-content: flex-start;
        margin-top: 0.5rem;
        dt {
          width: 100px;
          flex-shrink: 0;
          font-size: var(--fs-16);
          color: var(--bs-gray);
          text-align: left;
        }
        dd {
          font-size: var(--fs-16);
          flex: 1;
          text-align: left;
          .form-control {
            text-align: right;
          }
        }
      }
    }
  }
}
#admEdu-voucher-allotment {
  .table-total {
    position: relative;
    z-index: 1;
    box-shadow: 0px -4px 2px rgba(0, 0, 0, 0.05);
  }
}
#admEdu-voucher-status-detail {
  .sub-title {
    justify-content: flex-start;
    .badge {
      padding: 0.5rem;
      font-size: var(--fs-12);
      margin-right: 0.4rem;
      --bs-bg-opacity: 0.25 !important;
      &.bg-success {
        color: var(--bs-success);
      }
      &.bg-warning {
        color: var(--bs-warning);
      }
    }
  }
}
#admEdu-post-detail {
  .table {
    .post-content {
      text-align: left;
      min-height: 400px;
    }
    .attatchment-box {
      border: 0;
      margin: 0;
    }
  }
}
.write-form {
  .form-group {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    & > * {
      min-width: 1px;
    }
    .form-label {
      line-height: 42px;
      width: 120px;
      flex-shrink: 0;
      color: var(--bs-gray);
      font-size: var(--fs-16);
      font-weight: 500;
      & + * {
        flex: 1;
        max-width: 100%;
        font-size: var(--fs-16);
      }
    }
    .form-check {
      margin-right: 1rem;
      label {
        color: var(--bs-dark);
        font-size: var(--fs-16);
        font-weight: 500;
        line-height: 42px;
        padding-left: 0.25rem;
      }
    }
  }
}

.attatchment-box {
  margin-top: 1rem;
  padding: 0.5rem;
  border: 1px solid var(--bs-border);
  text-align: left;
  li {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 0.25rem 0 0.25rem 1.5rem;
    background: url('../images/ic_attatchment.png') no-repeat left 4px;
    & > * {
      min-width: 1px;
    }
    a {
      color: var(--bs-primary);
      text-decoration: underline;
      span {
        color: var(--bs-dark);
        text-decoration: none;
      }
    }
    .btn-delete {
      flex-shrink: 0;
      margin: 0.15rem 0 0.15rem 0.25rem;
      min-width: 24px;
      height: 24px;
      background: transparent url('../images/ic_delete.png') no-repeat center !important;
    }
  }
}

.modal-reg-question {
  .modal-body {
    border: 0 !important;
    ul.form-list {
      li {
        margin-bottom: 0.75rem;
        h5 {
          flex-basis: 100px;
        }
      }
    }
  }
}

#modal-reg-ip {
  .ip-input-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .form-control {
      margin-right: 0.5rem;
    }
  }
}
#admEdu-system-schedule {
  .card-body {
    article {
      border-bottom: 1px solid var(--bs-border);
      padding: 1.5rem;
      &.disabled {
        opacity: 0.5;
        .btn-area {
          .btn-outline-primary {
            border-color: var(--bs-gray) !important;
            color: var(--bs-gray) !important;
            pointer-events: none;
          }
        }
      }
      .step-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .badge {
          padding: 0.5rem 0.75rem;
          font-size: var(--fs-16);
          margin-right: 0.5rem;
          font-weight: 500;
        }
        h6 {
          font-size: var(--fs-20);
        }
      }
      ul {
        margin-top: 1rem;
        li {
          background: url('../images/ic_check_primary.png') no-repeat left
            0.5rem;
          padding: 0.5rem 0 0.5rem 2.5rem;
          font-size: var(--fs-20);
          font-weight: 500;
        }
      }
    }
    .btn-area {
      margin: 1rem 0 0;
      justify-content: flex-end;
      .btn-ts {
        color: var(--bs-primary) !important;
        font-weight: 700;
        background: transparent url('../images/ic_check_primary.png') no-repeat
          10px center / 24px auto;
        padding-left: 2.5rem;
        &:disabled {
          opacity: 1;
        }
      }
    }
  }
}
.flex-start {
  .form-check {
    margin-right: 1rem;
    label {
      color: var(--bs-dark);
      font-size: var(--fs-16);
      font-weight: 500;
      line-height: 42px;
      padding-left: 0.25rem;
    }
  }
}
#modal-reg-ip,
#modal-rating-comp,
#modal-reg-comp {
  ul.form-list {
    li {
      margin-bottom: 0.75rem;
      h5 {
        flex-basis: 100px;
      }
    }
  }
}
#modal-reg-comp {
  .modal-body {
    overflow: visible;
  }
}
/*---------------------------------------------------------------------
                      ↓ admSystem
-----------------------------------------------------------------------*/
.admSystem {
  font-size: var(--fs-12);
  * {
    font-size: var(--fs-12);
  }
  .table {
    th,
    td {
      font-size: var(--fs-14);
      padding: 0.5rem;
      * {
        font-size: var(--fs-14);
      }
    }
    th {
      font-weight: 500;
    }
  }
  .btn-area {
    margin: 1rem 0 0;
  }
  .btn {
    min-width: 140px;
    height: 42px;
    font-size: var(--fs-14);
    font-weight: 400;
    &.btn-sm {
      min-width: 60px;
    }
  }
  .dl-box {
    dl {
      justify-content: flex-start;
      dt {
        flex: 100px 0 0;
      }
      dd {
      }
    }
  }
  .flex-start {
    .form-check {
      label {
        font-size: var(--fs-12);
        line-height: 32px;
      }
    }
  }
  .form-control {
    &.input-search {
      min-width: 240px;
      padding-right: 2rem;
      background: url('../images/ic_search.png') no-repeat right 0.75rem center /
        14px;
    }
  }
  .sub-title {
    h5 {
      font-size: var(--fs-16);
    }
  }
  .row-table {
    .row {
      margin: 0;
      padding: 0;
      border: 1px solid var(--bs-border);
      border-top: 0;
      border-right: 0;
      align-items: stretch;
      .col {
        flex-shrink: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        word-break: break-all;
        vertical-align: middle;
        border-right: 1px solid var(--bs-border);
        padding: 0.25rem;
        min-height: 46px;
        a {
          color: var(--bs-primary);
          text-decoration: underline;
        }
        .btn {
          margin: 0 0.15rem;
        }
      }
    }
    .table {
      margin-top: -1px;
      &.edit-table {
        td {
          background: #f6f7f9;
        }
      }
    }
    &.main {
      .row {
        .col {
          flex: 25% 0 0;
        }
      }
    }
    &.detail {
      .row {
        .col {
          flex: 20% 0 0;
        }
      }
    }
  }
}

p.attatchment {
  padding-left: 1.25rem;
  background: url('../images/ic_attatchment.png') no-repeat left 4px / auto 18px;
  cursor: pointer;
}
