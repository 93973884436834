// table.scss

.table-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
  width: 100%;
}

.table-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  min-height: 44px;
  line-height: 44px;
  h5 {
    font-size: 20px;
    font-weight: bold;
  }
  h6 {
    font-size: 16px;
    font-weight: bold;
    color: #53535d;
  }
  .dl-box {
    &.type-bar {
      dl {
        dt {
          font-size: 20px;
          font-weight: bold;
        }
        dd {
          font-size: 20px;
          font-weight: bold;
          color: #53535d;
        }
        &:before {
          width: 2px;
          height: 18px;
          background-color: #bcbfcb;
        }
      }
    }
  }
}

.table-sub-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  line-height: 44px;
  h5 {
    padding: 0 16px;
    text-align: center;
    font-size: 1rem;
    color: var(--bs-primary);
  }
}

.table {
  table-layout: fixed;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  color: var(--bs-dark);
  border: 1px solid var(--bs-border);
  border-top: 2px solid var(--bs-dark);
  > :not(:first-child) {
    border-top: 0;
  }
  tr {
    &.disabled {
      background-color: #ecf1f4;
      pointer-events: none;
    }
    &.go-detail {
      &:hover {
        background-color: #fafcff !important;
        // background-color: #f2f6fb !important;
        cursor: pointer;
      }
    }
  }
  &.tr-even-bg {
    tbody {
      tr {
        &:nth-of-type(even) {
          background-color: #fafcff !important;
        }
        &.go-detail {
          &:hover {
            background-color: #f2f6fb !important;
            cursor: pointer;
          }
        }
      }
    }
  }
  &.type-narrow {
    th,
    td {
      padding: 0.5rem;
      line-height: normal;
    }
  }
  &.has-input {
    td {
      padding: 0.5rem;
    }
  }
  &.bt-none {
    border-top: 1px solid var(--bs-border) !important;
  }
  th,
  td {
    vertical-align: middle;
    padding: 0.5rem 1rem;
    height: 46px;
    border: 1px solid var(--bs-border);
    &.text-gray {
      color: #a4a5b6;
    }
  }
  th {
    font-size: var(--fs-14);
    font-weight: 800;
    background-color: #f2f6fb;
    color: #36363b;
  }
  td {
    font-size: var(--fs-14);
    font-weight: 500;
    small {
      display: inline-block;
      font-size: 14px;
      color: #53535d;
    }
    &.go-detail {
      &:hover {
        background-color: #f2f6fb !important;
        cursor: pointer;
      }
    }
  }
  .btn-area {
    margin-top: 0;
    margin-bottom: 0;
    .btn {
      margin-left: 0.5rem;
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

// split-table
.split-table {
  font-size: var(--fs-16);
  border-top: 2px solid var(--bs-dark) !important;
  border-bottom: 1px solid var(--bs-border);
  .table-row {
    border-right: 1px solid var(--bs-border);
    margin: 0;
    color: #323232;
    &:first-of-type {
      .th,
      .td {
        border-top: 0;
        padding-top: 0.7rem;
      }
    }
    .th,
    .td {
      font-size: var(--fs-16);
      height: 46px;
      padding: 0.65rem 1rem;
      display: inline-flex;
      align-items: center;
      font-weight: 500;
      text-align: left;
      border: 1px solid var(--bs-border);
      border-bottom: 0;
      border-right: 0;
      flex-shrink: 0;
      &.text-center {
        justify-content: center;
      }
    }
    .th {
      font-weight: 800;
      background-color: #f2f6fb;
    }
  }
  &.h-56 {
    .table-row {
      .th,
      .td {
        height: 56px;
      }
    }
  }
  &.bt-none {
    border-top: 1px solid var(--bs-border) !important;
  }
  &.has-img {
    border-bottom: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    .table-row {
      &:last-of-type {
        border-bottom: 1px solid var(--bs-border);
      }
    }
    .profile {
      flex-basis: 150px;
      flex-shrink: 0;
      margin-right: 12px;
      .img-box {
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .no-data {
        width: 100%;
        height: 100%;
        border: 1px solid var(--bs-border);
        border-top: none;
        background: #fafcfc url(../images/ic_user.png) no-repeat center / 80px;
      }
    }
    .table-row-wrap {
      flex-grow: 1;
    }
  }
  &.type-simple {
    border-bottom: 0;
    &.b-none {
      padding-top: 4px;
      .table-row {
        border-right: 0;
        .th {
          font-weight: 500;
          background-color: white;
          color: #a4a5b6;
        }
        .th,
        .td {
          border-bottom: 0;
        }
      }
    }
    &.bp-none {
      border: none !important;
      padding: none !important;
      .table-row {
        .th,
        .td {
          border: none;
          padding: 0;
        }
      }
    }
    .table-row {
      border-right: 0;
      .th {
        padding-left: 0;
        font-weight: 500;
        background-color: white;
        color: #a4a5b6;
      }
      .th,
      .td {
        height: auto;
        min-height: 54px;
        border-bottom: 1px solid var(--bs-border);
        border-top: 0;
        border-right: 0;
        border-left: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  &.type-unsplit {
    .th,
    .td {
      border-bottom: 1px solid var(--bs-border) !important;
    }
    .td {
      min-height: 46px;
      height: auto !important;
    }
    border-bottom: none;
  }
}
td.no-data {
  text-align: center;
  padding: 2rem 0;
  background: #fff;
  color: var(--bs-gray);
  font-size: var(--fs-14);
}
