// custom.scss

/*---------------------------------------------------------------------
                      ↓ main
-----------------------------------------------------------------------*/
main {
  // type 1 (학생)
  min-height: calc(100vh - 210px);
  padding-top: 139px;
  & > .container {
    padding: 0;
  }
  // type 2 (학생화면 외)
  &.type-02 {
    background: #f8fbff;
    padding-bottom: 100px;
    .card {
      padding: 0;
      box-shadow: 0px 0px 6px 1px rgb(0 0 0 / 10%);
      margin-bottom: 0;
      .card-header {
        background-color: white;
        padding: 24px;
        border-bottom: 0;
      }
      .card-body {
        background-color: white;
        padding: 24px;
      }
    }
    .title {
      margin: 30px 0;
      &.mb-60 {
        margin: 30px 0 60px 0;
      }
    }
  }
}

/*---------------------------------------------------------------------
                      ↓ title
-----------------------------------------------------------------------*/
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  line-height: 44px;
  margin: 30px 0 60px 0;
  h5 {
    font-size: var(--fs-24);
    font-weight: bold;
  }
  p {
    font-size: 16px;
    color: #aa9f9f;
    font-weight: 400;
  }
}
.sub-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  h5 {
    font-size: var(--fs-20);
    font-weight: bold;
    margin-right: 12px;
  }
  p {
    font-size: 16px;
    color: #aa9f9f;
    font-weight: 400;
  }
  &.sm {
    margin: 36px 0 24px 0;
    h5 {
      font-size: 18px;
    }
  }
  &.flex-start {
    justify-content: flex-start;
  }
  &.type-dot {
    margin-top: 24px;
    h5 {
      padding-left: 24px;
      color: #8c8ca1;
      font-size: 16px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        background-color: #8c8ca1;
        border-radius: 50%;
        top: -1px;
        bottom: 0;
        margin: auto;
        left: 8px;
      }
    }
  }
}

/*---------------------------------------------------------------------
                      ↓ flex
-----------------------------------------------------------------------*/
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
ul.type-flex-1 {
  width: 100%;
  display: flex;
  li {
    width: 20%;
    text-align: center;
  }
}

/*---------------------------------------------------------------------
                      ↓ btn 관련
-----------------------------------------------------------------------*/
.btn-area {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 6rem;
  .btn {
    margin-left: 0.5rem;
  }
}

/*---------------------------------------------------------------------
                      ↓ input 관련
-----------------------------------------------------------------------*/
// form-list
ul.form-list {
  margin-bottom: 1.6rem;
  &:last-of-type {
    margin-bottom: 0;
  }
  li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
    h5 {
      flex-basis: 180px;
      flex-shrink: 0;
      font-size: var(--fs-14);
      font-weight: 400;
      margin-bottom: 0;
      color: #6f6f7f;
    }
    .form-control {
      &[readonly] {
        font-weight: 500;
        background: transparent;
        padding: 0;
        border: none;
        font-size: var(--fs-14);
      }
    }
    &.type-split {
      width: 100%;
      div.flex-1 {
        width: 100%;
        display: flex;
        align-items: center;
        &:last-of-type {
          margin-left: 20px;
        }
      }
    }
  }
  &.top {
    li {
      align-items: flex-start;
      h5 {
        margin-top: 10px;
      }
    }
  }
  &.type-card {
    border-radius: 4px;
    padding: 1.25rem;
    background-color: #fff;
    box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 6%);
  }
}
.split-form-list {
  display: flex;
  ul.form-list {
    flex-grow: 1;
    h5.ms-4 {
      flex-basis: 120px;
    }
  }
  .add-profile {
    cursor: pointer;
    flex-basis: 180px;
    flex-shrink: 0;
    margin-left: 40px;
    border-radius: 4px;
    overflow: hidden;
    .img-box {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .no-data {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border: 1px solid #d4d8df;
      p {
        margin-top: 4px;
        font-size: 14px;
        font-weight: 500;
        color: #a4a5b6;
      }
    }
  }
}
// 내부에 sm사이즈 버튼이 포함된 input
.input-has-btn {
  width: 100%;
  position: relative;
  .form-control {
    padding-right: 72px;
    height: 52px;
  }
  .btn {
    position: absolute;
    top: -1px;
    bottom: 0;
    margin: auto;
    right: 6px;
    &.btn-sm {
      padding: 0 1rem;
      height: 40px;
      font-size: 16px;
    }
  }
}
// 추가,삭제 가능한 input (진로설계 > 자격증 취득계획)
.right-outside-btn-input {
  display: flex;
  margin-bottom: 12px;
  .btn {
    margin-left: 12px;
  }
}
// 휴대폰번호 입력 인풋
.input-phone {
  display: flex;
  align-items: center;
  .form-control {
    width: 90px;
  }
}
// 사이즈
.mini-input {
  width: 100px;
}
.sw-input {
  width: 180px;
  &.smallest {
    width: 140px;
  }
}
.mw-input {
  width: 200px;
}
.lw-input {
  width: 435px;
}
.xlw-input {
  width: 500px;
}
.w-100 {
  width: 100%;
  .form-control {
    &.input-datepicker {
      font-size: 1rem;
      background: white url('../images/ic_calendar.png') no-repeat 97% 44% /
        16px;
    }
  }
}
.form-switch {
  padding-left: 2.5rem;
  .form-check-input {
    border: 0;
    width: 2.25rem;
    height: 1.25rem;
    margin-left: -2.25rem;
    background-color: #dde6ff;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');
    background-position: left center;
    border-radius: 100px;
    transition: 0.2s ease-in-out;
    &:checked {
      background-color: var(--bs-primary);
      border-color: var(--bs-primary);
    }
  }
}

/*---------------------------------------------------------------------
                      ↓ text 관련
-----------------------------------------------------------------------*/
p.text-timer {
  font-size: var(--fs-12);
  color: #8c8ca1;
}
.text-blue {
  color: #326bff;
}
.text-dark {
  color: #53535d !important;
}
.text-red {
  color: #df1125;
}
.text-danger {
  color: var(--bs-danger) !important;
}
.fs-10 {
  font-size: var(--fs-10) !important;
}
.fs-12 {
  font-size: var(--fs-12) !important;
}
.fs-14 {
  font-size: var(--fs-14) !important;
}
.fs-16 {
  font-size: var(--fs-16) !important;
}
.fs-18 {
  font-size: var(--fs-18) !important;
}
.fs-20 {
  font-size: var(--fs-20) !important;
}
.fs-24 {
  font-size: var(--fs-24) !important;
}
.fs-28 {
  font-size: var(--fs-28) !important;
}
.fs-32 {
  font-size: var(--fs-32) !important;
}

/*---------------------------------------------------------------------
                      ↓ 파일첨부 관련
-----------------------------------------------------------------------*/
// 파일첨부 기본
ul.attachment-list {
  border-top: 2px solid var(--bs-dark) !important;
  border: 1px solid var(--bs-border);
  li {
    padding: 20px;
    border-bottom: 1px solid var(--bs-border);
    &:last-of-type {
      border-bottom: none;
    }
  }
}
// 파일첨부 드래그
.drag-box {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--bs-border);
  border-radius: 4px;
  background-color: white;
  &.hover {
    background-color: #e2e2e2;
  }
  p {
    position: relative;
    padding-left: 32px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      width: 24px;
      height: 24px;
      background: transparent url('../images/ic_dragFile.png') no-repeat center /
        24px;
    }
  }
}
ul.drag-attachment-list {
  &.sm {
    li {
      p {
        font-size: 15px;
      }
      padding: 10px 16px;
    }
  }
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin-bottom: 12px;
    border: 1px solid #d4d8df;
    border-radius: 4px;
    background-color: white;
    p {
      font-weight: 500;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

/*---------------------------------------------------------------------
                      ↓ box 스타일
-----------------------------------------------------------------------*/
.border-box {
  padding: 26px;
  background-color: white;
  border: 1px solid var(--bs-border);
}
.comment-box {
  padding: 26px;
  background-color: #fafcfc;
  border-top: 2px solid var(--bs-dark) !important;
  border: 1px solid var(--bs-border);
  &.type-primary {
    border-top: 2px solid var(--bs-primary) !important;
  }
  ul.comment-list {
    li {
      margin-bottom: 28px;
      &:last-of-type {
        margin-bottom: 0;
      }
      p {
        &.date {
          color: #a4a5b6;
          font-weight: 700;
          margin-bottom: 8px;
        }
      }
    }
  }
  .btn {
    height: auto;
  }
  &.disabled {
    padding: 0;
    background-color: transparent;
    border-top: none !important;
    border: none !important;
    padding: 0;
    ul.comment-list {
      li {
        border-radius: 4px;
        background-color: #d4d8df;
        padding: 26px;
        margin-bottom: 28px;
        &:last-of-type {
          margin-bottom: 0;
        }
        p {
          &.date {
            color: #a4a5b6;
            font-weight: 700;
            margin-bottom: 8px;
          }
          &.text {
            color: #6f6f7f;
          }
        }
      }
    }
  }
}

/*---------------------------------------------------------------------
                      ↓ dl-box
-----------------------------------------------------------------------*/
.dl-box {
  display: flex;
  flex-direction: column;
  dl {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    font-size: 18px;
    color: #191919;
    dt {
      font-weight: 500;
    }
    dd {
      font-weight: 400;
      strong {
        font-weight: bold;
        font-size: 36px;
      }
    }
  }
  &.type-bar {
    flex-direction: row;
    &.lg {
      dl {
        padding-left: 1rem;
        margin-left: 1rem;
        strong {
          font-size: 16px;
        }
        &:before {
          height: 24px;
          background-color: #bcbfcb;
        }
      }
    }
    dl {
      font-size: 16px;
      border: none;
      position: relative;
      padding-left: 0.65rem;
      margin-left: 0.65rem;
      &:before {
        content: '';
        display: block;
        width: 1px;
        height: 12px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        background-color: #b7b7b7;
      }
      &:first-of-type {
        padding: 0;
        margin: 0;
        &:before {
          display: none;
        }
      }
      dt {
        font-weight: bold;
      }
      dd {
        font-weight: 400;
        span {
          font-weight: bold;
          margin-left: 6px;
        }
      }
    }
  }
}

/*---------------------------------------------------------------------
                      ↓ 기타
-----------------------------------------------------------------------*/
.has-bt {
  border-top: 2px solid var(--bs-dark) !important;
}

// date picker 사이 물결 특수기호
.tilde {
  font-size: 18px;
  font-weight: 400;
  margin: 0 12px;
}

// 휴대폰입력 인풋 사이 hyphen 특수기호
.hyphen {
  font-size: 18px;
  font-weight: 400;
  margin: 0 12px;
}

// 경로
.path {
  display: flex;
  align-items: center;
  margin-top: 18px;
  li {
    padding-left: 18px;
    margin-left: 10px;
    background: transparent url('../images/ic_arrow_right.png') no-repeat 0% 50% /
      6px;
    a {
      font-size: 14px;
      font-weight: bold;
      color: var(--bs-gray);
      line-height: 20px;
    }
    &:first-of-type {
      margin-left: 0;
      padding-left: 0;
      background: none;
    }
    &:last-of-type {
      a {
        color: var(--bs-dark);
      }
    }
  }
}

// 주석
.annotation {
  color: var(--bs-green) !important;
  display: block;
  // margin-bottom: 0.5rem;
  &:before {
    content: '<!- - ';
  }
  &:after {
    content: ' - ->';
  }
}

// 서명
.signature-area {
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h5 {
    font-size: 22px;
    margin-bottom: 40px;
  }
  p {
    font-weight: 500;
    font-size: 18px;
    &:first-of-type {
      margin-bottom: 20px;
    }
  }
}

// 확정 문구 (선생님 > 학생관리 > 학생현황_부장선생님type)
.confirmation-area {
  position: relative;
  strong {
    position: absolute;
    top: 67px;
    right: 0;
    font-size: 18px;
  }
}

// no-data
section.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0 300px 0;
}

.chart-area {
  width: 100%;
  height: 300px;
  background: var(--bs-gray-bg);
  border: 1px solid var(--bs-border);
  .chart-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .simplebar-content {
    height: 100%;
    .chart-wide {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 800px;
      height: 100%;
    }
  }
}

.select-school {
  div[class$='-control'] {
    height: 42px;
    line-height: 42px;
    padding-left: 38px;
    background: url('../images/ic_school.png') no-repeat 0.5rem 48%;
  }
}

.search-box {
  display: flex;
  align-items: center;
  border: 1px solid var(--bs-border);
  border-radius: 0.25rem;
  & > * {
    min-width: 1px;
  }
  .select-inside-box {
    flex-shrink: 0;
    width: 120px;
    div[class$='-control'] {
      border: 0;
      position: relative;
      &:after {
        content: '';
        display: block;
        width: 1px;
        height: 24px;
        background: var(--bs-border);
        position: absolute;
        top: calc(50% - 12px);
        right: 0;
      }
    }
  }
}
.swal2-title {
  font-size: 1.25rem;
  color: var(--bs-dark);
}
.swal2-html-container {
  font-size: 1rem;
  color: var(--bs-gray-dark);
  font-weight: 500;
}
.swal2-actions {
  margin-top: 1rem;
}
.swal2-styled {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  transition: 0.2s ease-in-out;
  &.swal2-confirm {
    background: var(--bs-primary);
    &:hover {
      background: var(--bs-primary-hover);
    }
  }
  &.swal2-cancel {
    background: var(--bs-ts) !important;
    border: 1px solid var(--bs-primary) !important;
    color: var(--bs-primary) !important;
    &:hover {
      background: var(--bs-primary-light) !important;
    }
  }
}

.list-circle {
  li {
    list-style: disc inside;
    color: var(--bs-gray);
    &::marker {
      font-size: 4px;
    }
  }
}
.ratio {
  &.ratio-3x4 {
    --bs-aspect-ratio: 133%;
  }
}

// Form 상태 텍스트
.status-text {
  line-height: normal;
  text-align: center;
  small {
    color: var(--bs-gray-500) !important;
  }
}

// print
@media print {
  html,
  body {
    margin: 0;
    page-break-after: avoid;
    page-break-before: avoid;
  }
  .body,
  .print {
    margin-top: -1px;
  } /*서브픽셀 해결1*/
  .print {
    min-width: inherit;
    max-width: inherit;
    width: auto;
    -webkit-print-color-adjust: exact;
    // 공통
    small {
      font-size: 10px !important;
    }
    th,
    td,
    .th,
    .td,
    p {
      font-size: 12px !important;
      height: auto !important;
    }
    h5 {
      font-size: 14px !important;
    }
    .mb-5 {
      margin-bottom: 30px !important;
    }
    table {
      page-break-inside: auto;
      thead {
        display: table-header-group;
      }
      tr {
        page-break-inside: avoid;
        page-break-after: auto;
      }
    }
    .path,
    .btn-area {
      opacity: 0;
      height: 0;
      margin: 0;
      overflow: hidden;
    }
    .title {
      margin: 0 0 30px !important;
      h5,
      .dl-box dd,
      .dl-box dd strong {
        font-size: 16px !important;
      }
    }
    .sub-title {
      page-break-inside: auto;
      page-break-after: avoid;
    }
    .print-remove-col {
      colgroup {
        col {
          width: auto !important;
        }
        col:last-child {
          display: none;
        }
      }
      tr {
        th,
        td {
          &:last-child {
            display: none;
          }
        }
      }
    }
    // 포트폴리오
    .signature-area {
      padding: 30px 0;
    }
  }
  @page {
    margin: 12mm;
    size: A4 portrait;
    overflow: hidden;
    // zoom: 98%;
  }
}
